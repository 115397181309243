import React from 'react';
import styled from 'styled-components/macro';
import Logo from '../Logo/Logo'

const FlexCenter = styled.div`
    display: flex;
    justify-content: center;
`;

const Header = () => (
    <FlexCenter>
        <Logo />
    </FlexCenter>
);

export default Header;