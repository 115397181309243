import React from 'react';
import styled from 'styled-components/macro';

const LogoText = styled.h1`
    font-family: Audiowide;
`;

const Logo = () => (
    <>
        <LogoText>lidlbidl</LogoText>
    </>
);

export default Logo;